export default {
  ENDPOINT: "https://beardrug-service.yuzudigital.com/",
  // ENDPOINT: "https://clinic-service-demo.yuzudigital.com/",
  // ENDPOINT: "http://localhost:8081/",

  // Client do-main
  ENDPOINT_CLIENT: "https://pos-beardrug.yuzudigital.com",
  // ENDPOINT_CLIENT: "https://apps.beluca.co",
  // ENDPOINT_CLIENT: "http://localhost:8080",
};
